@tailwind base;
@tailwind components;
@tailwind utilities;

/* Smooth scrolling for all scrolling containers */
html {
  scroll-behavior: smooth;
}

/* Ensure the root element takes full height */
html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Font settings and antialiasing for smoother type rendering */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Normalize paragraph spacing */
p {
  margin: 0;
  padding: 0;
}
